import { useState } from "react";
import { useSearchStocksQuery } from "../../../store/slice/tradeApi";
import styles from "./searchStock.module.css";

function SearchStock({ selectedItem, setSelectedItem }) {

  const [queriString, setQueriString] = useState("");

  const [skip, setSkip] = useState(true);

  const { data: searchResult, isSuccess: searchIsSuccess } =
    useSearchStocksQuery(queriString, { skip });

  const handleInputChange = (e) => {
    setQueriString(e.target.value);
    e.target.value.length > 2 ? setSkip(false) : setSkip(true);
  };

  // ex de resultat de recherche
  /*
    id: "GOOG",
    longExchange: "NASDAQ",
    longName: "Alphabet Inc.",
    place: "NMS",
    title: "Alphabet Inc."
  "*/

  return (
    <div className={styles.search}>
      <h2>Sélection d'instrument : </h2>
      <form>
        <label htmlFor="stock">votre recherche :  </label>
        <input
          type="stock"
          name="stock"
          id="stock"
          placeholder="minimum 3 caractères"
          value={queriString}
          onChange={handleInputChange}
          autoFocus
        />
      </form>
      {searchIsSuccess && (!searchResult | (searchResult && searchResult.length === 0)) &&
        <p> Aucun resultat </p>
      }
      {searchIsSuccess && (searchResult && searchResult.length > 0) &&
        <>
          <table>
            <thead>
              <tr>
                <th>ticker</th>
                <th>nom</th>
                <th>exchange</th>
                <th>type</th>
              </tr>
            </thead>
            <tbody>
              {searchResult.map((item, i) => (
                <tr
                  key={i}
                  onClick={() =>
                    setSelectedItem({
                      id: item.id,
                      title: item.title,
                      isin: item.isin,
                      place: item.place,
                      ticker: item.ticker,
                      type: item.type,
                      longExchange: item.longExchange,
                      longName: item.longName,
                    })
                  }
                >
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                  <td data-to-focus={item.longExchange} >{item.place}</td>
                  <td>{item.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </div>
  );
}

export default SearchStock;
