import React from "react";
import { useState } from "react";
import styles from "./nav.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/slice/user";
import { resetStorage } from "../../utils/tools";
import { useForceUpdateQuotesMutation } from "../../store/slice/tradeApi";

function Nav() {
  let isVisitor = true;

  const role = (useSelector)((state) => state.user.infos.role)
  if (
    role !== "visitor"
  ) {
    isVisitor = false;
  }

  // retourne le nom du connecté :
  const alias = useSelector((state) => state.user.infos.alias);

  const [showLinks, setShowLinks] = useState(false);

  if (showLinks) {
    document.body.classList.add`blockedY`;
  } else {
    document.body.classList.remove`blockedY`;
  }


  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };
  const dispatch = useDispatch();

  const handleLogOut = () => {
    // on efface le localStorage
    resetStorage();
    // on reset le state
    dispatch(signOut());
  };

  // Le hook RTK Query pour la mutation
  const [forceUpdateQuotes] = useForceUpdateQuotesMutation();

  const forceRefreshQuotes = async () => {
    try {
      // Déclenche la mutation
      await forceUpdateQuotes().unwrap();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des cours :", error);
    }
  };

  return (
    <div
      className={`${styles.navbar} ${showLinks ? styles.show_nav : styles.hide
        }`}
    >
      <button className={styles.navbar_burger} onClick={handleShowLinks}>
        <span className={styles.burger_bar}></span>
      </button>
      <ul className={styles.navbar_links} onClick={handleShowLinks}>
        {!isVisitor && (
          <>
            <li className={styles.navbar_item}>
              <NavLink
                className={`${styles.button} ${styles.navbar_link}`}
                to="/newTrade"
              >
                Créer un nouveau trade
              </NavLink>
            </li>
            <li className={styles.navbar_item}>
              <NavLink
                className={`${styles.button} ${styles.navbar_link}`}
                to="/portfolio/manage"
              >
                Gérer les portefeuilles
              </NavLink>
            </li>
            <li className={styles.navbar_item}>
              <NavLink
                className={`${styles.button} ${styles.navbar_link}`}
                to="/strategies"
              >
                Gérer les stratégies
              </NavLink>
            </li>
          </>
        )}
        {role === "admin" &&
          <li className={styles.navbar_item}>
            <NavLink
              className={`${styles.button} ${styles.navbar_link}`}
              onClick={forceRefreshQuotes}
              to="/"
            >
              Force refresh quotes
            </NavLink>
          </li>
        }

        <li className={styles.navbar_item}>
          <NavLink
            className={`${styles.button} ${styles.navbar_link}`}
            onClick={handleLogOut}
            to="/"
          >
            Se déconnecter
          </NavLink>
        </li>
        { }
        <li className={styles.navbar_item}>
          Connecté(e) : {alias}
        </li>
      </ul>
    </div>
  );
}

export default Nav;
